var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    attrs: {
      "id": "sub-wrap",
      "justify-center": "",
      "align-start": ""
    }
  }, [_c('v-responsive', {
    staticClass: "sub-contents",
    attrs: {
      "width": "100vw",
      "max-width": "1200"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "sub_head_title clearfix"
  }, [_c('div', {
    staticClass: "fLeft"
  }, [_c('h1', [_vm._v("대량거래/체인점문의")])])]), _c('div', {
    staticClass: "inquiry-section"
  }, [_c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": "/images/couponstory/inquiry-img.png",
      "alt": "악수이미지"
    }
  })]), _c('p', {
    staticClass: "page-text"
  }, [_c('strong', {
    staticClass: "color-orange"
  }, [_vm._v("대량거래")]), _vm._v(" 또는 "), _c('strong', {
    staticClass: "color-orange"
  }, [_vm._v("사업")]), _vm._v("에 관심이 있는 분은 "), _c('strong', [_vm._v("언제든지 연락주세요")])]), _c('div', {
    staticClass: "img-wrap mb-0"
  }, [_c('img', {
    attrs: {
      "src": "/images/couponstory/inquiry-img2.png",
      "alt": "대량거래시 수수료 우대. 핀박스만의 특화된 사업모델 시스템을 제공해드립니다. 대량거래/체인점 선택 후 Pin-box@naver.com 이메일 접수"
    }
  }), _c('p', [_vm._v("* 연락처 성함 필수이며 담당자 검토 후 24시간 이내 연락 드리겠습니다")])])]), _c('div', {
    staticClass: "inquiry-section2"
  }, [_c('div', {
    staticClass: "inquiry-info"
  }, [_c('div', {
    staticClass: "inquiry-info-left"
  }, [_c('img', {
    attrs: {
      "src": "/images/couponstory/ci.png",
      "alt": "핀박스"
    }
  })]), _c('div', {
    staticClass: "inquiry-info-right"
  }, [_c('p', [_c('strong', [_vm._v("주식회사 쿠폰스토리")])]), _c('p', [_c('em', [_vm._v("대표번호")]), _vm._v("010-3712-8272")]), _c('p', [_c('em', [_vm._v("이메일")]), _vm._v("couponstory@daum.net")])])])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }